<script>
export default {
  name: 'rankingFunctions',
  data() {
    return {
      currentMacroFilter: 'Meine Klasse',
      currentMicroFilter: 'Gesamt',
      macroFilter: ['Meine Klasse', 'Alle'],
      microFilter: ['Gesamt', 'Lernpfade', 'Training', 'Battle'],
    };
  },

  computed: {
    me() {
      return this.$store.state.profile.me;
    },
    myUserDataId() {
      return this.$store.state.profile.me?.userData?.data?.id;
    },
    myProfileImage() {
      return this?.me?.userData?.data?.attributes?.avatar?.data?.attributes?.url;
    },
    myNickname() {
      return this?.me?.userData?.data?.attributes?.nickname;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    currentClassId() {
      return this.$store.state.settings.currentClassId;
    },
    myPoints() {
      return this.me?.userData?.data?.attributes?.points;
    },
    battlePoints() {
      let battlePoints = 0;
      this.myPoints.battlePoints.forEach((point) => {
        if (point.courseId === this.currentCourseId) battlePoints += point.points;
      });
      return battlePoints;
    },
    mcTrainingPoints() {
      let mcTrainingPoints = 0;
      this.myPoints.mcTrainingPoints.forEach((point) => {
        if (point.courseId === this.currentCourseId) mcTrainingPoints += point.points;
      });
      return mcTrainingPoints;
    },
    pfadaufgabenPoints() {
      let pfadaufgabenPoints = 0;
      this.myPoints.pfadaufgabenPoints.forEach((point) => {
        if (point.courseId === this.currentCourseId) pfadaufgabenPoints += point.points;
      });
      return pfadaufgabenPoints;
    },
    lernpfadPoints() {
      let lernpfadPoints = 0;
      const lernpfadProgress = this.me?.userData?.data?.attributes?.lernpfadProgress?.lernpfade;
      lernpfadProgress.forEach((lernpfad) => {
        if (lernpfad.courseId === this.currentCourseId) {
          lernpfad.lernspots.forEach((lernspot) => {
            lernpfadPoints += lernspot.slides.length;
          });
        }
      });
      return lernpfadPoints;
    },
    totalPoints() {
      if (this.currentMicroFilter === 'Gesamt') {
        return this.battlePoints + this.mcTrainingPoints + this.pfadaufgabenPoints + this.lernpfadPoints;
      }
      if (this.currentMicroFilter === 'Lernpfade') {
        return this.lernpfadPoints;
      }
      if (this.currentMicroFilter === 'Training') {
        return this.mcTrainingPoints + this.pfadaufgabenPoints;
      }
      if (this.currentMicroFilter === 'Battle') {
        return this.battlePoints;
      }
      return 0;
    },
    ranking() {
      return this.$store.state.ranking.ranking;
    },
    filteredRanking() {
      const filteredResult = [];
      if (this.ranking) {
        this.ranking.forEach((user, index) => {
          filteredResult.push(user);
          filteredResult[index].totalPoints = this.getPoints(user.attributes.points);
        });
        filteredResult.sort((a, b) => b.totalPoints - a.totalPoints);
      }
      return filteredResult;
    },
    myRank() {
      let myRank = 0;
      if (this.filteredRanking) {
        this.filteredRanking.forEach((user, index) => {
          if (user.id === this.myUserDataId) {
            myRank = index + 1;
          }
        });
      }
      return myRank;
    },
  },

  methods: {
    getPoints(points) {
      let battlePoints = 0;
      if (points?.battlePoints?.length > 0) {
        points.battlePoints.forEach((point) => {
          if (point.courseId === this.currentCourseId) battlePoints += point.points;
        });
      }

      let mcTrainingPoints = 0;
      if (points?.mcTrainingPoints?.length > 0) {
        points.mcTrainingPoints.forEach((point) => {
          if (point.courseId === this.currentCourseId) mcTrainingPoints += point.points;
        });
      }

      let pfadaufgabenPoints = 0;
      if (points?.pfadaufgabenPoints?.length > 0) {
        points.pfadaufgabenPoints.forEach((point) => {
          if (point.courseId === this.currentCourseId) pfadaufgabenPoints += point.points;
        });
      }

      let lernpfadPoints = 0;
      if (points?.lernpfadPoints) {
        if (points.lernpfadPoints.courseId === this.currentCourseId) lernpfadPoints += points.lernpfadPoints.points;
      }
      const totalPoints = battlePoints + mcTrainingPoints + pfadaufgabenPoints + lernpfadPoints;

      if (this.currentMicroFilter === 'Gesamt') {
        return totalPoints;
      }
      if (this.currentMicroFilter === 'Lernpfade') {
        return lernpfadPoints;
      }
      if (this.currentMicroFilter === 'Training') {
        return mcTrainingPoints + pfadaufgabenPoints;
      }
      if (this.currentMicroFilter === 'Battle') {
        return battlePoints;
      }
      return 0;
    },
  },
};
</script>
