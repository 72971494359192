<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryRanking',
  watch: {
    ranking() {
      this.$store.commit('setStrapiResult', { vuexModule: 'ranking', state: 'ranking', data: this.ranking?.ranking?.data });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-and-network',
    });

    const store = useStore();

    const queryRanking = gql`
      query($courseId: ID!, $classId: ID) {
        ranking(courseId: $courseId, classId: $classId) {
          data {
            id
            attributes {
              points
              nickname
              avatar {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      courseId: computed(() => store.state.settings.currentCourseID),
      classId: computed(() => store.state.settings.filterClassId),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: ranking } = useQuery({
      query: queryRanking,
      variables,
      context,
    });
    return { ranking };
  },
};
</script>
