<template>
  <div class="font-visby text-new-black p-5 border-b border-new-yellow2  flex flex-row justify-between items-center cursor-pointer">
    <div class="flex flex-row justify-start items-center flex-shrink-0">
      <div>{{ position }}</div>
      <div class="w-14 h-14 mx-5 rounded-full bg-white border-new-yellow2 border-2 overflow-hidden">
        <div v-if="image" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
        <div v-else class="w-full h-full p-1">
          <profileSVG class="w-full h-full" />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center flex-grow">
      <div>{{ name }}</div>
      <div>{{ points }}</div>
    </div>
  </div>
</template>

<script>
// SVG
import profileSVG from '../../../assets/svg/profile.vue';

export default {
  name: 'rankingItem',
  props: ['position', 'name', 'points', 'image'],
  components: { profileSVG },
};
</script>
