<template>
  <div class="bg-white rounded-xl h-auto w-3/4 min-w-60 md:w-1/4 shadow-soft p-3 text-new-black font-visby cursor-pointer" :class="{'w-full' : single}">
    <div class="bg-white w-full h-48 rounded-lg shadow-soft bg-cover bg-no-repeat bg-center" :style="{ backgroundImage: 'url(' + previewImage + ')' }"></div>
    <div class="mt-3">
      <div class="font-visby font-bold text-lg break-words">{{ heading }}</div>
    </div>
    <div class="mt-2 md:mt-1">
      <div class="relative w-full h-1.5 bg-new-lightGrey2 rounded-full">
        <div class="absolute h-full bg-new-orange rounded-full" :style="{'background-color': currentCourseColor2}" :class="[calculateWidth(lernspotsTotal, lernspotsDone)]"></div>
      </div>
      <div class="text-new-midGrey mt-1 text-xs text-right italic">{{ lernspotsRemaining }}</div>
    </div>
  </div>
</template>

<script>
// Mixins
import colors from '../../../mixins/colors.vue';
import progressBarWidth from '../../../mixins/progressBarWidth.vue';

export default {
  name: 'slideItem',
  props: ['heading', 'lernspotsTotal', 'lernspotsDone', 'previewImage', 'itemName', 'single'],
  mixins: [colors, progressBarWidth],

  computed: {
    lernspotsRemaining() {
      const remaining = this.lernspotsTotal - this.lernspotsDone;
      if (remaining === 0) {
        return 'abgeschlossen';
      }
      if (remaining === 1) {
        return `noch ${remaining} ${this.itemName}`;
      }
      return `noch ${remaining} ${this.itemName}s`;
    },
  },
};
</script>
