<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryListMc',
  watch: {
    listMc() {
      this.$store.commit('setListMc', this.listMc?.mcFragens?.data);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryListMc = gql`
      query($ids: [ID!], $courseId: ID!) {
        mcFragens(filters: {
          id: { in: $ids }, kurs: { id: { eq: $courseId }}
          }) {
          data {
            id
            attributes {
              Index
            }
          }
        }
      }
    `;

    const variables = reactive({
      ids: computed(() => store.getters.listEntries('mcFragen')),
      courseId: computed(() => store.state.settings.currentCourseID),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: listMc } = useQuery({
      query: queryListMc,
      variables,
      context,
    });
    return { listMc };
  },
};
</script>
