<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryLernpfadPreview',
  watch: {
    lernpfadPreview() {
      this.$store.commit('setLernpfadPreview', this.lernpfadPreview?.lernpfades?.data);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryLernpfadPreview = gql`
      query($ids: [ID!]) {
        lernpfades(filters: { id: {in: $ids}}) {
          data {
            id
            attributes {
              Bezeichnung
              titelbild {
                data {
                  attributes {
                    url
                  }
                }
              }
              lernspots(pagination: {limit:999}) {
                id
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      ids: computed(() => store.state.dashboard.lernpfadPreviewIds),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: lernpfadPreview } = useQuery({
      query: queryLernpfadPreview,
      variables,
      context,
    });
    return { lernpfadPreview };
  },
};
</script>
