<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryListLernspots',
  watch: {
    listLernspots() {
      this.$store.commit('setListLernspots', this.listLernspots?.lernspots?.data);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryListLernspots = gql`
      query($ids: [ID!], $courseId: ID!) {
        lernspots(ids: $ids, courseId: $courseId) {
          data {
            id
            attributes {
              Bezeichnung
              lernspots {
                id
                Bezeichnung
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      ids: computed(() => store.getters.listEntries('lernpfade')),
      courseId: computed(() => store.state.settings.currentCourseID),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: listLernspots } = useQuery({
      query: queryListLernspots,
      variables,
      context,
    });
    return { listLernspots };
  },
};
</script>
