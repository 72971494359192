<template>
  <div class="font-visby text-new-black py-5 flex flex-row justify-between items-center cursor-pointer" :class="{'border-b border-new-yellow2' : border}">
    <div>
      <div>{{ listName }}</div>
      <div class="text-sm text-new-midGrey">{{ listContent }}</div>
    </div>
    <chevron />
  </div>
</template>

<script>
// SVG
import chevron from '../../../assets/svg/chevron.vue';

export default {
  name: 'myListItemDashboard',
  props: ['listName', 'listContent', 'border'],
  components: { chevron },
};
</script>
